<template>
  <div @click="$router.go(-1)" class="p-mb-4" style="width: fit-content; cursor: pointer;">
    <i class="pi pi-arrow-left" style="display: inline-block; padding-right: 4px;"></i><h5 style="display: inline-block;"> Regresar</h5>
  </div>
  <div>
    <Card style="width: auto; margin-bottom: 2em">
      <template #title>
        <div style="display: flex;">
          <div style="flex: 1 1 0;">
            Actualizar Restaurante
          </div>
<!--          <div style="flex: 1 1 0; text-align: right; margin-right: 4px;">Verificado: <span :style="restaurant.verified ? 'color: #22C55E' : 'color: #EF4444'">{{ restaurant.verified ? 'Si' : 'No'}}</span></div>-->
        </div>
      </template>
      <template #content>
        <div style="display: flex; justify-content: center;">
          <div style="flex: 1 1 auto; margin-right: 25px;">
            <div style="display:block; margin: 0 0 0 auto; width: fit-content;">
              <div style="">
                <h4 >Nombre de Restaurante</h4>
                <InputText id="name" type="text" v-model="body.name" style="width: 20rem;"/>
              </div>
              <div class="p-mt-4">
                <h4 >Nombre de Contacto</h4>
                <InputText id="name" type="text" v-model="body.contact_name" style="width: 20rem;"/>
              </div>
              <div class="p-mt-4">
                <h4>Email</h4>
                <InputText type="text" v-model="body.email" style="width: 20rem;" />
              </div>
              <div class="p-mt-4">
                <h4>Instagram Username</h4>
                <InputText type="text" v-model="body.ig_username" style="width: 20rem;" />
              </div>
              <div class="p-mt-4">
                <h4>CIF</h4>
                <InputText type="text" v-model="body.cif" style="width: 20rem;" />
              </div>
            </div>
          </div>

          <div style="flex: 1 1 auto;">
            <div>
              <h4>Teléfono</h4>
              <InputText type="text" v-model="body.phone" style="width: 20rem;"/>
            </div>
            <div class="p-mt-4">
              <h4>Dirección</h4>
              <InputText type="text" v-model="body.address" style="width: 20rem;"/>
            </div>
            <div class="p-mt-4">
              <h4>Ciudad</h4>
              <InputText type="text" v-model="body.city" style="width: 20rem;"/>
            </div>
            <div class="p-mt-4">
              <h4>Estado/Provincia</h4>
              <InputText type="text" v-model="body.state" style="width: 20rem;"/>
            </div>
            <div class="p-mt-4">
              <h4>Zip Code</h4>
              <InputText type="text" v-model="body.zip_code" style="width: 20rem;"/>
            </div>
          </div>
        </div>
        <Button @click="updateFoodie" style="display: block; margin: 0 auto;" class="p-mt-4">Actualizar</Button>

      </template>
    </Card>
  </div>
  <Toast />
</template>
<script>
export default {
  name: 'update',
  data () {
    return {
      restaurant: [],
      body: {
        name: '',
        biography: '',
      },
      ig_username: '',
      email: '',
      errors: []
    }
  },
  methods: {
    updateFoodie () {

      delete this.body.latitude
      delete this.body.longitude
      this.$repository.restaurants
        .update(this.$route.params.restaurantId, this.body)
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: `El restaurante ha sido actualizado`,
            life: 1000
          });
        })
        .catch((err) => {
          const summary = err.response.data?.message
          const detail = err.response.data?.description
          this.$toast.add({
            severity: 'error',
            summary,
            detail,
            life: 3000
          });
        })
    }
  },
  mounted () {
    this.$repository.restaurants
      .show(this.$route.params.restaurantId)
      .then((response) => {
        this.restaurant = response.data
        this.body = { ...response.data }
        //   name: response.data.name,
        //   contact_name: response.data.contact_name,
        //   address: response.data.address,
        //   city: response.data.city,
        //   state: response.data.state,
        //   zip_code: response.data.zip_code,
        //   email: response.data.email,
        //   ig_username: response.data.ig_username
        // }
        if (response?.data?.phone) {
          this.body.phone = response.data.phone
        }
      })
  }
}
</script>

<style scoped>

</style>
